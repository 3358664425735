import { action, atom } from "@reatom/core";

import { AxiosError } from "axios";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { archiveBoardListAtom, removeFromArchiveBoardAction } from "@/entities/archive-board";
import {
	inspirationBoardListAtom,
	removeFromInspirationBoardAction
} from "@/entities/inspiration-board";
import {
	Attrs,
	TGridType,
	TMoodBoardStatus,
	TRightSidebarView
} from "@/entities/moodboard/lib";
import { callErrorAction } from "@/entities/notification";
import { removeFromSearchBoardAction, searchBoardListAtom } from "@/entities/search-board";
import {
	addMoodboardImages,
	addMoodboardsImagesCoordinates,
	deleteMoodboardImages,
	getMoodboardsResource,
	TMoodboardImage,
	TMoodBoardImageCoordinate,
	UpdateMoodboardImage
} from "@/shared/api/moodboard";
import { getImgSize, urlToBlob } from "@/shared/methods";

export const moodboardLoadingAtom = atom<boolean>(false);
export const moodBoardNamesAtom = atom<string[]>([], "moodBoardNamesAtom");
export const moodBoardListAtom = atom<TMoodboardImage[]>([], "moodBoardListAtom");
export const moodBoardGridTypeAtom = atom<TGridType>("chaos", "moodBoardGridTypeAtom");
export const moodBoardSidebarView = atom<TRightSidebarView>("inspiration");
export const moodBoardStatusAtom = atom<TMoodBoardStatus>((ctx) => {
	const list = ctx.spy(moodBoardListAtom);

	return list.length > 0 ? "full" : "empty";
}, "moodBoardStatusAtom");

export const shadowMoodboardButtonsAtom = atom<Attrs[]>([], "shadowMoodboardButtonsAtom");
moodBoardListAtom.onChange((ctx, newState) => {
	const oldButtons = ctx.get(shadowMoodboardButtonsAtom);

	const buttons: Attrs[] = newState.map((el, index) => ({
		x: el.coordinates.x,
		y: el.coordinates.y,
		width: el.coordinates.w,
		isLocked: false,
		isActive: false,
		name: el.id,
		group: oldButtons[index]?.group ?? null
	}));

	shadowMoodboardButtonsAtom(ctx, buttons);
});

export const changeMoodboardButtonPositionAction = action((ctx, params: Attrs) => {
	const buttons = ctx.get(shadowMoodboardButtonsAtom);
	const updatedButtons = buttons.map((button) => button.name === params.name ? params : button);

	shadowMoodboardButtonsAtom(ctx, updatedButtons);
}, "chnageMoodboardButtonPositionAction");

export const toggleMoodboardButtonActiveAction = action((ctx, id: string, isActive?: boolean) => {
	const buttons = ctx.get(shadowMoodboardButtonsAtom);
	const updatedButtons = buttons.map((button) => ({
		...button,
		isActive: typeof isActive === "boolean" ? isActive : button.name === id
	}));

	shadowMoodboardButtonsAtom(ctx, updatedButtons);
}, "changeMoodboardButtonActiveAction");

export const addGroupButtonAction = action((ctx, id: string, group: Konva.Group | null) => {
	const buttons = ctx.get(shadowMoodboardButtonsAtom);

	const updatedButtons = buttons.map((button) => ({
		...button,
		group: button.name === id ? group : button.group
	}));

	shadowMoodboardButtonsAtom(ctx, updatedButtons);
});

export const getMoodboardAction = action(async (ctx, id: string) => {
	try {
		moodboardLoadingAtom(ctx, true);

		const { data } = await getMoodboardsResource(id);

		const imagesPromises: Promise<TMoodboardImage>[] = data.images.filter((img) => !img.archived).map(async (image) => {
			const { width, height } = await getImgSize(image.url);

			if (image.coordinates.w === 0 || image.coordinates.h === 0) {
				image.coordinates.w = width;
				image.coordinates.h = height;
				image.coordinates.minW = width;
				image.coordinates.minH = height;
			}

			return image;
		});

		const images = await Promise.all(imagesPromises);

		moodBoardListAtom(ctx, images);
	} catch (err) {
		moodBoardListAtom(ctx, []);
		moodboardLoadingAtom(ctx, false);
		callErrorAction(ctx, err as AxiosError, true);
	} finally {
		moodboardLoadingAtom(ctx, false);
	}
});

export const addImageToMoodboardAction = action(async (ctx, id: string, formData: FormData, coordinates: TMoodBoardImageCoordinate[]) => {
	try {
		moodboardLoadingAtom(ctx, true);
		const { data } = await addMoodboardImages(id, formData);

		await addMoodboardsImagesCoordinates(id, Object.keys(data).map((id, index) => ({
			...coordinates[index],
			id
		})));
		await getMoodboardAction(ctx, id);
	} catch (err) {
		moodboardLoadingAtom(ctx, false);
		callErrorAction(ctx, err as AxiosError, true);
	}
});

export const dropImageToMoodboardAction = action(async (ctx, id: string, formData: FormData, coordinates: TMoodBoardImageCoordinate) => {
	try {
		const { data } = await addMoodboardImages(id, formData);

		const newId = Object.keys(data)[0];
		const image = {
			...coordinates,
			id: newId
		};

		await addMoodboardsImagesCoordinates(id, [image]);

		return newId;
	} catch (err) {
		callErrorAction(ctx, err as AxiosError, true);
	}
});

export const updateMoodboardImagesPositionAction = action(async (ctx, projectName: string, imageCoordinate: TMoodBoardImageCoordinate, id: string) => {
	const list = ctx.get(moodBoardListAtom);

	const images: UpdateMoodboardImage[] = list.map((image) => {
		if (image.id === id) {
			return {
				...imageCoordinate,
				id
			};
		}

		return {
			...image.coordinates,
			id: image.id
		};
	});

	await addMoodboardsImagesCoordinates(projectName, images);
}, "updateMoodboardImagesPosition");

export const clearMoodBoardAction = action(async (ctx, id) => {
	try {
		moodboardLoadingAtom(ctx, true);
		const imageIdList = ctx.get(moodBoardListAtom).filter((img) => !img.archived).map((image) => image.id);

		await deleteMoodboardImages(id, imageIdList);
		await getMoodboardAction(ctx, id);
	} catch (err) {
		moodboardLoadingAtom(ctx, false);
		callErrorAction(ctx, err as AxiosError, true);
	}
});

export const removeFromMoodBoardAction = action(async (ctx, moodboardId: string, imageId: string) => {
	try {
		moodBoardListAtom(ctx, ctx.get(moodBoardListAtom).filter((item) => item.id !== imageId));

		await deleteMoodboardImages(moodboardId, [imageId]);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError, true);
	}
});

export const addToMoodBoardAction = action((ctx, image: TMoodboardImage) => {
	moodBoardListAtom(ctx, [...ctx.get(moodBoardListAtom), image]);
});

export const updateAllMoodBoardAction = action((ctx, images: TMoodboardImage[]) => {
	moodBoardListAtom(ctx, [...images]);
});

type Params = {
	id: string;
	dropId: string;
	position?: Vector2d | null
}

type DropImage = {
	image: TMoodboardImage;
} & Params;
export const toDropImage = action(async (ctx, params: DropImage) => {
	const imageBlob = await urlToBlob(params.image.url);
	const file = new File([imageBlob!], params.dropId);
	const formData = new FormData();
	formData.append("images", file);

	const newId = await dropImageToMoodboardAction(ctx, params.id as string, formData, {
		...params.image.coordinates,
		x: params?.position?.x ?? params.image.coordinates.x ?? 0,
		y: params?.position?.y ?? params.image.coordinates.y ?? 0
	});

	addToMoodBoardAction(ctx, {
		...params.image,
		id: newId ?? params.image.id,
		coordinates: {
			...params.image.coordinates,
			x: params?.position?.x ?? params.image.coordinates.x ?? 0,
			y: params?.position?.y ?? params.image.coordinates.y ?? 0
		}
	});
});
export const dropImageFromSidebarsAction = action(async (ctx, params: Params) => {
	const inspirationBoardList = ctx.get(inspirationBoardListAtom);
	const searchBoardList = ctx.get(searchBoardListAtom);
	const archiveBoardList = ctx.get(archiveBoardListAtom);

	const imageFromInspirationBoard = inspirationBoardList.find((item) => item.id === params.dropId);
	const imageFromSearchBoard = searchBoardList.find((item) => item.id === params.dropId);
	const imageFromArchiveBoard = archiveBoardList.find((item) => item.id === params.dropId);

	if (params.id) {
		if (imageFromArchiveBoard) {
			removeFromArchiveBoardAction(ctx, params.dropId);

			await toDropImage(ctx, {
				...params,
				image: imageFromArchiveBoard
			});
		}

		if (imageFromSearchBoard) {
			removeFromSearchBoardAction(ctx, params.dropId);

			await toDropImage(ctx, {
				...params,
				image: imageFromSearchBoard
			});
		}

		if (imageFromInspirationBoard) {
			removeFromInspirationBoardAction(ctx, params.dropId);

			await toDropImage(ctx, {
				...params,
				image: imageFromInspirationBoard
			});
		}
	}
});
