import * as Sentry from "@sentry/react";
import { ComponentType, FC, useEffect } from "react";
import ReactGA from "react-ga4";
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";

import { BlooperExample } from "@/pages/blooper-example";
import { Character } from "@/pages/character";
import { CharacterInfo } from "@/pages/character-info";
import { Contacts } from "@/pages/contacts";
import { CreateNewPassword } from "@/pages/create-new-password";
import { EnterTheCode } from "@/pages/enter-the-code";
import { Error404, Error500 } from "@/pages/errors";
import { Invitation } from "@/pages/invitation";
import { LandingTeam } from "@/pages/landing-team";
import { Login } from "@/pages/login";
import { Moodboard } from "@/pages/moodboard";
import { Payment } from "@/pages/payment";
import { PaymentSuccess } from "@/pages/payment-success";
import { Plan } from "@/pages/plan";
import { Product } from "@/pages/product";
import { Projects } from "@/pages/projects";
import { RecoverPassword } from "@/pages/recover-password";
import { Register } from "@/pages/register";
import { Script } from "@/pages/script";
import {
	SettingsAccount,
	SettingsBilling,
	SettingsPreferences,
	SettingsProfile,
	SettingsTeam
} from "@/pages/settings";
import { ShotEdit } from "@/pages/shot-edit";
import { Storyboard } from "@/pages/storyboard";
import { SuccessPasswordChanged } from "@/pages/success-password-changed";
import { TeamManage } from "@/pages/team-manage";
import { UseCaseScript } from "@/pages/use-case-script";
import { UseCaseStoryboard } from "@/pages/use-case-storyboard";
import { UseCaseVideo } from "@/pages/use-case-video";
import { SuccessEmail, Verify } from "@/pages/verification";

import { HomeLeftSidebar } from "@/widgets/home-left-sidebar";
import { LandingLayout } from "@/widgets/landing-layout";
import { SettingsLayout } from "@/widgets/settings-layout";
import { StoryboardSidebar } from "@/widgets/storyboard-sidebar";
import { ErrorWrapper, SuccessWrapper } from "@/entities/notification/ui";
import { getCredential } from "@/entities/viewer/lib/utils";
import { NOT_RELEASE } from "@/shared/const/release";

interface IRoute {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: ComponentType<any>;
}

const AuthRoute: FC<IRoute> = ({ component: Component }) => {
	const viewer = getCredential()?.data;
	const isAuth = !!viewer?.key;

	if (isAuth) {
		return <Navigate to="/main/projects" />;
	}

	return <Component />;
};

const PrivateRoute: FC<IRoute> = ({ component: Component }) => {
	const viewer = getCredential()?.data;
	const isAuth = !!viewer?.key;

	if (!isAuth) {
		return <Navigate to="/login" />;
	}

	return (
		<>
			<ErrorWrapper>
				<SuccessWrapper>
					<Component />
				</SuccessWrapper>
			</ErrorWrapper>
		</>
	);
};

export const PublicRoute: FC<IRoute> = ({ component: Component }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: pathname });
	}, []);

	return (
		<Component />
	);
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
	createBrowserRouter
);

export const router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <LandingLayout />,
		children: [
			{
				path: "",
				element: <PublicRoute component={Product} />
			},
			{
				path: "blooper-example",
				element: <PublicRoute component={BlooperExample} />
			},
			{
				path: "use-case-video",
				element: <PublicRoute component={UseCaseVideo} />
			},
			{
				path: "use-case-storyboard",
				element: <PublicRoute component={UseCaseStoryboard} />
			},
			{
				path: "use-case-script",
				element: <PublicRoute component={UseCaseScript} />
			},
			{
				path: "contacts",
				element: <PublicRoute component={Contacts} />
			},
			{
				path: "team",
				element: <PublicRoute component={LandingTeam} />
			}
		]
	},
	{
		path: "/storyboard/:id",
		element: <PrivateRoute component={StoryboardSidebar} />,
		children: [
			{
				path: "script",
				element: <Script />
			},
			{
				path: "moodboard",
				element: <Moodboard />
			},
			{
				path: "character",
				element: <Character />
			},
			{
				path: "character/:characterId/character-info",
				element: <CharacterInfo />
			},
			{
				path: "storyboard",
				element: <Storyboard />
			},
			{
				path: "shot-selection/:sceneId/:shotId",
				element: <ShotEdit />
			}
		]
	},
	{
		path: "/main",
		element: <PrivateRoute component={HomeLeftSidebar} />,
		children: [
			{
				path: "team-manage",
				element: <TeamManage />
			},
			{
				path: "projects",
				element: <Projects />
			},
			{
				path: "settings",
				element: <SettingsLayout />,
				children: [
					{
						path: "profile",
						element: <SettingsProfile />
					},
					{
						path: "account",
						element: <SettingsAccount />
					},
					{
						path: "members",
						element: <SettingsTeam />
					},
					{
						path: "preferences",
						element: <SettingsPreferences />
					},
					{
						path: "billing",
						element: <SettingsBilling />
					}
				]
			},
			{
				path: "404",
				element: <Error404 />
			},
			{
				path: "500",
				element: <Error500 />
			}
		]
	},
	{
		path: "/login",
		element: <AuthRoute component={Login} />
	},
	{
		path: "/register",
		element: NOT_RELEASE ? <AuthRoute component={Register} /> : <Error404 />
	},
	{
		path: "/success-email/:email",
		element: <AuthRoute component={SuccessEmail} />
	},
	{
		path: "/plan",
		element: <AuthRoute component={Plan} />
	},
	{
		path: "/auth/verify",
		element: <AuthRoute component={Verify} />
	},
	{
		path: "/auth/verify/invitation",
		element: <Invitation />
	},
	{
		path: "/payment-success",
		element: <AuthRoute component={PaymentSuccess} />
	},
	{
		// TODO fix mobile version
		path: "/payment/:termType",
		element: NOT_RELEASE ? <AuthRoute component={Payment} /> : <Error404 />
	},
	{
		path: "/recover-password",
		element: NOT_RELEASE ? <AuthRoute component={RecoverPassword} /> : <Error404 />
	},
	{
		// TODO fix mobile version
		path: "/enter-the-code/:email",
		element: NOT_RELEASE ? <AuthRoute component={EnterTheCode} /> : <Error404 />
	},
	{
		path: "/create-new-password/:email",
		element: NOT_RELEASE ? <AuthRoute component={CreateNewPassword} /> : <Error404 />
	},
	{
		path: "/password-success-changed",
		element: NOT_RELEASE ? <AuthRoute component={SuccessPasswordChanged} /> : <Error404 />
	},
	{
		path: "/404",
		element: <PublicRoute component={Error404} />
	},
	{
		path: "/500",
		element: <PublicRoute component={Error500} />
	},
	{
		path: "*",
		element: <Error404 />
	}
]);
