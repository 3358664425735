import { reatomAsync } from "@reatom/async";
import { atom } from "@reatom/core";

import { axiosErrorHandler } from "@/shared/api";
import { createUserGoogleResource, createUserResource, TAuthCode, TRegisterCredentials } from "@/shared/api/auth";
import { IMessageError } from "@/vite-env";

export const keyAtom = atom<null | string>(null, "user atom");
export const errorAtom = atom<null | string>(null, "error atom");

export const isSuccessEmailSendAtom = atom<boolean>(false, "isSuccessEmailSendAtom");

export const createUserAction = reatomAsync(
	(_ctx, cred: TRegisterCredentials) => createUserResource(cred),
	{
		name: "create user action",
		onFulfill (ctx) {
			isSuccessEmailSendAtom(ctx, true);
		},
		onReject (ctx, err) {
			isSuccessEmailSendAtom(ctx, false);
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				if (error.response?.status === 403) {
					errorAtom(ctx, "Incorrect email or password");
				} else if (error.response?.status === 409) {
					errorAtom(ctx, "User with this email already exists");
				} else {
					errorAtom(ctx, "Oops, something went wrong");
				}
			} else {
				errorAtom(ctx, "Oops, something went wrong");
			}
		}
	});

export const createUserGoogleAction = reatomAsync(
	(_ctx, cred: TAuthCode) => createUserGoogleResource(cred),
	{
		name: "create user action",
		onFulfill (ctx, result) {
			keyAtom(ctx, result.data.key);
		},
		onReject (ctx, err) {
			const { type, error } = axiosErrorHandler<IMessageError<string>>(err as Error);

			if (type === "axios-error") {
				errorAtom(ctx, error.response?.data?.message ?? "");
			}
		}
	});
