import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, PropsWithChildren } from "react";

import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import logo from "@/assets/viewer/logo.svg";

import "./LoginWrapper.scss";

type Props = {
	title: string;
	questionText: string | (string | JSX.Element)[];
	actionText: string;
	action: () => void;
}

export const LoginWrapper: FC<PropsWithChildren<Props>> = ({ children, title, questionText, actionText, action }) => {
	const { isMobileL, isLaptopS } = useMediaQueries();

	return (
		<Flex className="login-wrapper full-width full-height">
			<Flex
				flex={1}
				justify="center"
				align="center"
				className="login-wrapper__content full-width"
			>
				<Flex vertical>
					<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH2, {
						[TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopS,
						[TEXT_CLASSNAMES.LPHeadlineH4]: isMobileL
					})}>{title}</Typography.Text>
					<Flex align="center">
						<Typography.Text
							className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary, {
								[TEXT_CLASSNAMES.XsRegular]: isLaptopS
							})}
						>
							{questionText}
						</Typography.Text>
						&nbsp;
						{NOT_RELEASE && (
							<Typography.Text
								onClick={action}
								className={cn(TEXT_CLASSNAMES.SmSemibold, COLOR_CLASSNAMES.TextSecondary, "pointer auth-wrapper__action-text", {
									[TEXT_CLASSNAMES.XsRegular]: isLaptopS
								})}
							>
								{actionText}
							</Typography.Text>
						)}
					</Flex>
					{children}
				</Flex>
			</Flex>
			<Flex vertical justify="space-between" flex={1} className="login-wrapper__space">
				<Flex align="center" justify="center" flex={1}>
					<div className="login-wrapper__space__img">
						<img className="image-contain" src={logo} alt="logo"/>
					</div>
				</Flex>
				<Flex flex={0} justify="flex-end">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>© Blooper 2024. All rights reserved.</Typography.Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
