import { AxiosResponse } from "axios";
import { api } from "@/shared/api";
import { ProjectThumbnailResult, TCreateProjectParams, TProjectResult } from "@/shared/api/project/types";

export const createProjectResource = (params: TCreateProjectParams): Promise<AxiosResponse<unknown>> => api.post("/projects", { ...params });

export const getAllProjectsResource = (): Promise<AxiosResponse<TProjectResult>> => api.get("/projects");

export const getProjectByNameResource = (name: string): Promise<AxiosResponse<TProjectResult>> => api.get(`/projects/${name}`);

export const deleteProjectResource = (name: string): Promise<AxiosResponse<unknown>> => api.delete(`/projects/${name}/`);

export const updateProjectNameResource = (oldName: string, newName: string): Promise<AxiosResponse<unknown>> => api.patch(`/projects/${oldName}/${newName}`);

export const getThumbnailResource = (name: string): Promise<AxiosResponse<ProjectThumbnailResult>> => api.get(`/projects/thumbnails/${name}`);

export const addThumbnailResource = (name: string, formData: FormData, controller: AbortController): Promise<AxiosResponse<unknown>> => api.post(
	`/projects/thumbnails/${name}`,
	formData,
	{
		signal: controller.signal,
		headers: { "Content-Type": "multipart/form-data" }
	});

export const deleteThumbnailResource = (name: string): Promise<AxiosResponse<unknown>> => api.delete(`/projects/thumbnails/${name}`);
