import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC, useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { howuku } from "react-howuku";
import { RouterProvider } from "react-router-dom";

import { router } from "@/pages";

import { PermissionWrapper } from "@/entities/viewer";
import { AntDProvider, ReatomProvider } from "./providers";

import "./styles/index.css";

export const App: FC = () => {
	useEffect(() => {
		if (import.meta.env.PROD) {
			ReactGA.initialize([{
				trackingId: import.meta.env.VITE_GOOGLE_ANALYTICS_ID
			}]);
			howuku.initialize(import.meta.env.VITE_HOWUKU_ANALYTICS_ID);

			TagManager.initialize({
				gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER
			});
		}
	}, []);

	return (
		<ReatomProvider>
			<AntDProvider>
				<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
					<PermissionWrapper>
						<RouterProvider router={router} />
					</PermissionWrapper>
				</GoogleOAuthProvider>
			</AntDProvider>
		</ReatomProvider>
	);
};
