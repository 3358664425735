// import { Flex, Typography } from "antd";
import { FC } from "react";
import { AuthWrapper, BackgroundPreLogin } from "@/entities/viewer";
// import { COLOR_CLASSNAMES } from "@/shared/const/color-names.ts";
// import { Spinner } from "@/shared/ui";

export const PaymentSuccess: FC = () => {
	return (
		<BackgroundPreLogin>
			<AuthWrapper
				action={() => {}}
				title="Succcess Payment"
				questionText=""
				actionText=""
			/>
		</BackgroundPreLogin>
	);
};
