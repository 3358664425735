import { useAction, useAtom } from "@reatom/npm-react";
import { useKeyPress } from "ahooks";
import { Flex, Typography } from "antd";
import { FC, Fragment } from "react";

import { useParams } from "react-router-dom";
import { editingShotAtom } from "@/features/highlight-script";
import { SceneTitle } from "@/features/highlight-script/ui/scene-title";
import { backAction, forwardAction, sceneListAtom } from "@/entities/script";

import { Shot } from "./shot";

import "./HighlightScript.scss";

export const HighlightScript: FC = () => {
	const { id } = useParams();
	const [editingShot] = useAtom(editingShotAtom);
	const [sceneList] = useAtom(sceneListAtom);
	const historyBack = useAction(backAction);
	const historyForward = useAction(forwardAction);

	useKeyPress(["ctrl.z", "meta.z"], () => {
		if (!editingShot && id) {
			historyBack(id);
		}
	}, { exactMatch: true });

	useKeyPress(["shift.ctrl.z", "shift.meta.z"], () => {
		if (!editingShot && id) {
			historyForward(id);
		}
	});

	const lastShots = sceneList[sceneList.length - 1]?.shots;
	const lastId = lastShots?.[lastShots.length - 1]?.id;

	return (
		<Flex vertical className="highlight-the-shots full-height gap-xs">
			<Typography.Text className="text-center highlight-the-shots__title">“{id}”</Typography.Text>
			<Flex vertical>
				{sceneList.map((scene) => (
					<Fragment key={scene.id}>
						<SceneTitle sceneId={scene.id} idx={scene.idx} title={scene.title} />
						{scene.shots.map((shot) => (
							<Shot
								key={`${shot.id}-${JSON.stringify(shot.prompt)}`}
								sceneId={scene.id}
								shotId={shot.id}
								color={shot.color}
								title={shot.title}
								content={shot.prompt}
								shotIdx={shot.idx}
								isLast={shot.id === lastId}
							/>
						))}
					</Fragment>
				))}
			</Flex>
		</Flex>
	);
};
