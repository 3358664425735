import { drawDOM, pdf } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { useAction, useAtom } from "@reatom/npm-react";
import { MouseEvent, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { storyboardViewAtom } from "@/features/storyboard-view";
import { Generate, GenerateOrRegenerate } from "@/features/storyboard-view/utils";
import { updateGenerateImageAction } from "@/entities/storyboard";
import { generateStoryboardShotResource, regenerateImageResource } from "@/shared/api/storyboard";

export const useBehaviour = () => {
	const updateGenerateImage = useAction(updateGenerateImageAction);

	const { id } = useParams();
	const [tableView, setTableView] = useAtom(storyboardViewAtom);
	const targetRef = useRef<HTMLElement>(null);
	const [isSaving, setIsSaving] = useState(false);

	const toPDF = async () => {
		setIsSaving(true);
		if (targetRef.current) {
			const paperSize = tableView === "grid" ? [1250, 805] : [1950, 850];
			const options = { paperSize, landscape: false };
			const group = await drawDOM(targetRef.current, {
				// @ts-ignore
				paperSize: options.paperSize,
				landscape: options.landscape,
				repeatHeaders: true,
				scale: {
					x: 1,
					y: 1
				}
			});

			// @ts-ignore
			pdf.exportPDF(group, options)
				.then((data) => {
					saveAs(data, `${id}.pdf`);
				})
				.finally(() => {
					setIsSaving(false);
				});
		}
	};

	const callFunctionOnEachArrayElementInBatches = async (array: Generate[], callback: (projectId: string) => (shot: Generate) => Promise<GenerateOrRegenerate>) => {
		const notGeneratedShots = array.filter((shot) => !shot.isRegenerate);
		notGeneratedShots.forEach((shot) => {
			updateGenerateImage({ shotId: shot.shotId, loading: true, image: null, error: false });
		});

		while (notGeneratedShots.length > 0) {
			const chunkSize = 5;
			const batch = notGeneratedShots.splice(0, chunkSize);

			if (id) {
				await Promise.allSettled(batch.map(callback(id)));
			}
		}
	};

	const generateOrRegenerateImage = (projectId: string) => async (shot: Generate): Promise<GenerateOrRegenerate> => {
		let image = null;
		let error = false;

		try {
			if (shot.isRegenerate) {
				const shotInfo = await regenerateImageResource({ shotId: shot.shotId, projectId }, new AbortController());
				image = shotInfo.data.image_url ?? "";

				return {
					image,
					shotId: shot.shotId,
					sceneId: shot.sceneId
				};
			} else {
				const shotInfo = await generateStoryboardShotResource({ shotId: shot.shotId, projectId });
				image = shotInfo.data.image_url ?? "";

				return {
					image,
					shotId: shot.shotId,
					sceneId: shot.sceneId
				};
			}
		} catch (e) {
			error = true;
			console.error(e);
			return {
				image: "",
				shotId: shot.shotId,
				sceneId: shot.sceneId
			};
		} finally {
			updateGenerateImage({ shotId: shot.shotId, loading: false, image, error });
		}
	};

	const onGenerateImages = useCallback((shots: Generate[]) => {
		return async (event: MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			await callFunctionOnEachArrayElementInBatches(shots, generateOrRegenerateImage);
		};
	}, []);

	return {
		onGenerateImages,
		id,
		tableView,
		setTableView,
		toPDF,
		targetRef,
		isSaving
	};
};
