import { Flex, Layout, Menu, MenuProps, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./SettingsLayout.scss";

type MenuItem = Required<MenuProps>["items"][number];
type MenuItemKey = "profile" | "account" | "members" | "billing" | "preferences";

export const SettingsLayout: FC = () => {
	const navigate = useNavigate();
	const [current, setCurrent] = useState<MenuItemKey>("profile");

	const notReleaseMembers = [
		{
			label: (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
					[TEXT_CLASSNAMES.XsBold]: current === "preferences",
					[COLOR_CLASSNAMES.TextPrimary]: current === "preferences"
				})}>Preferences</Typography.Text>
			),
			key: "preferences"
		},
		{
			label: (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
					[TEXT_CLASSNAMES.XsBold]: current === "billing",
					[COLOR_CLASSNAMES.TextPrimary]: current === "billing"
				})}>Billing</Typography.Text>
			),
			key: "billing"
		}
	];

	const releaseItems: MenuItem[] = [
		{
			label: (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
					[TEXT_CLASSNAMES.XsBold]: current === "profile",
					[COLOR_CLASSNAMES.TextPrimary]: current === "profile"
				})}>Profile</Typography.Text>
			),
			key: "profile"
		},
		{
			label: (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
					[TEXT_CLASSNAMES.XsBold]: current === "account",
					[COLOR_CLASSNAMES.TextPrimary]: current === "account"
				})}>Account</Typography.Text>
			),
			key: "account"
		},
		{
			label: (
				<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular, COLOR_CLASSNAMES.TextSecondary, {
					[TEXT_CLASSNAMES.XsBold]: current === "members",
					[COLOR_CLASSNAMES.TextPrimary]: current === "members"
				})}>Members</Typography.Text>
			),
			key: "members"
		}
	];

	const items = NOT_RELEASE ? [...releaseItems, ...notReleaseMembers] : [...releaseItems];

	const onClick: MenuProps["onClick"] = (e) => {
		setCurrent(e.key as MenuItemKey);
		navigate(`${e.key}`);
	};

	useEffect(() => {
		const pathnameArray = location.pathname.split("/");
		const routeName = pathnameArray[pathnameArray.length - 1] as MenuItemKey;

		setCurrent(routeName);
	}, [location.pathname]);

	return (
		<Layout
			className="settings-layout gap-s"
			style={{
				background: COLOR_NAMES.MetalGray100,
				height: "100vh"
			}}
		>
			<Flex vertical>
				<Typography.Text className={TEXT_CLASSNAMES.HeadlineH1}>Settings</Typography.Text>
				<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Control your profile setup and projects settings</Typography.Text>
			</Flex>
			<Menu style={{
				background: "transparent"
			}} items={items} selectedKeys={[current]} onClick={onClick} mode="horizontal" />
			<Layout.Content style={{
				padding: 0,
				overflow: "initial",
				background: COLOR_NAMES.MetalGray100,
				height: "100%",
				overflowY: "scroll"
			}}>
				<Outlet />
			</Layout.Content>
		</Layout>
	);
};
