import { reatomAsync, withAbort, withStatusesAtom, withErrorAtom, withDataAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { withLocalStorage } from "@reatom/persist-web-storage";
import { callErrorAction } from "@/entities/notification";
import {
	GenerateImage,
	GenerateImageParams,
	REGENERATE_STORYBOARD_IMAGES,
	TStoryboardScene,
	TStoryboardShot
} from "@/entities/storyboard";
import {
	GetShotParams,
	getStoryboardResource,
	getStoryboardShotResource,
	RegenerateImageParams,
	regenerateImageResource,
	SBShot
} from "@/shared/api/storyboard";

export const getStoryboardAbortController = atom<AbortController>(new AbortController(), "getStoryboardAbortController");

export const generateImageAtom = atom<GenerateImage>({}, "generateImageAtom");

export const updateGenerateImageAction = action((ctx, params: GenerateImageParams) => {
	const generateImage = ctx.get(generateImageAtom);

	generateImageAtom(ctx, {
		...generateImage,
		[params.shotId]: {
			pending: params.loading,
			image: params.image === null ? generateImage[params.shotId]?.image : params.image,
			error: params.error
		}
	});
});

export const getShotAction = reatomAsync(
	(ctx, params: GetShotParams) => getStoryboardShotResource(params, ctx.controller)
).pipe(
	withAbort(),
	withStatusesAtom(),
	withDataAtom(),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);

export const regenerateImageAction = reatomAsync(async (ctx, params: RegenerateImageParams) => {
	await regenerateImageResource(params, ctx.controller);

	return params;
}, {
	onFulfill: (ctx, data) => {
		getShotAction(ctx, { shotId: data.shotId, projectId: data.projectId });
		regeneratedImagesAtom(ctx, { ...ctx.get(regeneratedImagesAtom), [data.shotId]: true });
	}
}).pipe(
	withAbort(),
	withStatusesAtom(),
	withErrorAtom()
);

export const regeneratedImagesAtom = atom<{ [shotId: string]: boolean }>({}, "regeneratedImages").pipe(withLocalStorage(REGENERATE_STORYBOARD_IMAGES));

export const getStoryboardAction = reatomAsync((ctx, projectId: string) => {
	const controller = ctx.get(getStoryboardAbortController);
	return getStoryboardResource(projectId, controller);
}, "getStoryboardAction").pipe(
	withStatusesAtom(),
	withDataAtom([], (ctx, res) => {
		const regeneratedImages = ctx.get(regeneratedImagesAtom);
		const toShot = (shotsInfo: SBShot, shotsOrder: string[]): TStoryboardShot[] => {
			const shots: TStoryboardShot[] = Object.entries(shotsInfo).map(([shotId, shot]) => ({
				id: shotId, // guid
				idx: shotsOrder.findIndex((order) => order === shotId),
				time: shot.time ?? 0,
				location: shot.location ?? "",
				shotCharacteristics: shot.characteristics,
				description: shot.description ?? "",
				props: [], // max length 8
				cameraMovement: shot.camera_movement ?? "",
				shotSettings: shot.settings, // close-up | f/2.8
				dialogue: shot.dialog ?? "",
				characters: shot.characters,
				image: regeneratedImages[shotId] ? `${shot.image_url}?no-cache=${Date.now()}` : (shot.image_url ?? ""),
				title: shot.title ?? "",
				cameraAngle: shot.camera_angle ?? ""
			}));

			return shots.sort((shotA, shotB) => shotA.idx - shotB.idx);
		};
		const toScenes = () => {
			const updatedList: TStoryboardScene[] = Object.entries(res.data.scenes_info).map(([id, scene]) => ({
				id,
				tableView: "grid",
				idx: res.data.scenes_order.findIndex((order) => order === id),
				title: scene.title,
				shots: toShot(scene.shots_info, scene.shots_order)
			}));

			return updatedList.sort((sceneA, sceneB) => sceneA.idx - sceneB.idx);
		};

		const scenes = toScenes();

		return scenes;
	}),
	withErrorAtom((ctx, err) => callErrorAction(ctx, err))
);
