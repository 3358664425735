import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PlanType } from "@/pages/plan/ui/PlanType";
import { promoCodeAtom, PromotionalCode } from "@/entities/plan";
import { BackgroundPreLogin, CREDENTIAL__REGISTER_KEY, LoginWrapper, sessionLoginAction } from "@/entities/viewer";
import { TRegisterKey } from "@/shared/api/auth";
import { useLocalStorage } from "@/shared/hooks";

import "./Plan.scss";

export const Plan: FC = () => {
	const navigate = useNavigate();
	const [activePlan, setActivePlan] = useState("single");
	const sessionLogin = useAction(sessionLoginAction);
	const [promoCode] = useAtom(promoCodeAtom);
	const [viewer] = useLocalStorage<TRegisterKey | null>(CREDENTIAL__REGISTER_KEY, null);

	const startUsing = () => {
		if (viewer) {
			sessionLogin({ key: viewer.key });
			navigate("/main/projects");
		}
	};

	const onChange = (value: string) => {
		setActivePlan(value);
	};

	console.log("==========>promoCode", promoCode);
	return (
		<BackgroundPreLogin>
			<LoginWrapper title="Choose your plan" questionText="" actionText="" action={() => {}}>
				<Flex vertical className="gap-xs">
					<PlanType
						activeValue={activePlan}
						name="plan"
						value="single"
						title="Single User"
						description="1 user, only shorts script, 5 projects maximum, Watermarks"
						priceText="$100/mo."
						onChange={onChange}
						disabled={false}
					/>
					<PlanType
						activeValue={activePlan}
						name="plan"
						value="business"
						title="Business"
						description="20 users, Unlimited projects, Watermarks (option),Custom characters, Collaboration features, Movie pitch, Premium support"
						priceText="Coming soon"
						onChange={onChange}
						disabled
					/>
					<PlanType
						activeValue={activePlan}
						name="plan"
						value="enterprise"
						title="Enterprise"
						description="Unlimited users, Unlimited projects, No watermarks"
						priceText="Contact us"
						onChange={onChange}
						disabled={false}
					/>
				</Flex>
				<Flex className="gap-xs" vertical style={{ marginTop: 24 }}>
					<PromotionalCode />
					<Button type="primary" onClick={startUsing}>Continue</Button>
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};
