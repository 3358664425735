import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Typography } from "antd";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { shotsOrderAtom, getNextShotId, getPrevShotId } from "@/entities/shot-edit";
import { regenerateImageAction } from "@/entities/storyboard";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { SidebarNavigation } from "@/shared/ui";
import { ShotDetailsInfo } from "./shot-details-info";

export const ShotDetails: FC = () => {
	const regenerateImage = useAction(regenerateImageAction);

	const [regenerateImageStatus] = useAtom(regenerateImageAction.statusesAtom);
	const [shots] = useAtom(shotsOrderAtom);
	const { id, sceneId, shotId } = useParams();
	const navigate = useNavigate();
	const currentShot = shots.find((shot) => shot.id === shotId);
	const onNext = () => {
		const list = shots.map((id) => ({ id }));
		const nextId = getNextShotId(list.map((shot) => shot.id), shotId);

		navigate(`/storyboard/${id}/shot-selection/${sceneId}/${nextId}`);
	};

	const onPrev = () => {
		const list = shots.map((id) => ({ id }));
		const prevId = getPrevShotId(list.map((shot) => shot.id), shotId);

		navigate(`/storyboard/${id}/shot-selection/${sceneId}/${prevId}`);
	};

	if (!currentShot) {
		return null;
	}

	const onRegenerate = async () => {
		if (shotId && id && sceneId) {
			await regenerateImage({ shotId, projectId: id });
		}
	};

	return (
		<Flex vertical justify="space-between" className="full-height full-width">
			<Flex style={{ overflowY: "scroll" }} vertical justify="space-between" className="full-width gap-xs">
				<Flex vertical className="gap-xs">
					<SidebarNavigation
						title={`Scene ${currentShot.sceneIdx + 1}. Shot ${currentShot.shotIdx + 1}`}
						onNext={onNext}
						onPrev={onPrev}
					/>
					<ShotDetailsInfo />
				</Flex>
			</Flex>
			<Flex style={{ paddingTop: 8 }} vertical className="gap-xs">
				<Button disabled={regenerateImageStatus.isPending} type="primary" className="flex-ctr-ctr" onClick={onRegenerate}>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Regenerate</Typography.Text>
				</Button>
				{/* <Button> */}
				{/*	<Typography.Text className={TEXT_CLASSNAMES.XsRegular}> */}
				{/*		Show in the script */}
				{/*	</Typography.Text> */}
				{/* </Button> */}
			</Flex>
		</Flex>
	);
};
