import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import "./PlanType.scss";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

type Props = {
	activeValue: string;
	name: string;
	value: string;
	title: string;
	description: string;
	priceText: string;
	onChange: (value: string) => void;
	disabled: boolean;
}

export const PlanType: FC<Props> = ({ activeValue, name, value, onChange, priceText, description, title, disabled }) => {
	return (
		<Flex className={cn("plan-type cubic-animation", {
			"plan-type--active": activeValue === value,
			"plan-type--disabled": disabled
		})}>
			<input
				id={`${name}-${value}`}
				className="plan-type__input"
				type="radio"
				name={name}
				value={value}
				onChange={(event) => onChange(event.target.value)}
				disabled={disabled}
			/>
			<span className={cn("plan-type__radio cubic-animation", {
				"plan-type__radio--active": activeValue === value
			})} />
			<Flex vertical className="plan-type__text">
				<Typography.Text className={TEXT_CLASSNAMES.MdSemibold}>
					{title}
				</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.SmRegular, COLOR_CLASSNAMES.TextSecondary)}>
					{description}
				</Typography.Text>
			</Flex>
			<Typography.Text style={{
				minWidth: "max-content"
			}} className={TEXT_CLASSNAMES.MdSemibold}>
				{priceText}
			</Typography.Text>
		</Flex>
	);
};
