import { useAtom } from "@reatom/npm-react";
import { Flex } from "antd";
import { FC } from "react";
import { ChangeTeamPermissionsModal } from "@/features/change-team-permissions-modal";
import {
	InviteNewMember,
	SearchTeamMember,
	ChangeTeamView,
	teamMemberViewAtom,
	TeamListCard,
	TeamGridCard,
	teamListAtom,
	searchTeamMemberAtom
} from "@/features/team-settings";
import { TTeamMember } from "@/features/team-settings/lib";

export const FullTeamManage: FC = () => {
	const [teamList] = useAtom(teamListAtom);
	const [teamMemberView] = useAtom(teamMemberViewAtom);
	const [searchTeamMember] = useAtom(searchTeamMemberAtom);

	const isContainValue = (team: TTeamMember) => {
		const isContainEmail = team.email.toLowerCase().includes(searchTeamMember.toLowerCase());
		const isContainName = team.name.toLowerCase().includes(searchTeamMember.toLowerCase());

		return isContainEmail || isContainName;
	};

	const filteredTeamList = teamList.filter(isContainValue);

	return (
		<Flex vertical className="gap-s">
			<Flex align="center" justify="space-between" className="full-width">
				<Flex className="gap-xs">
					<SearchTeamMember />
					<ChangeTeamView />
				</Flex>
				<InviteNewMember />
			</Flex>
			{teamMemberView === "grid" && (
				<Flex wrap="wrap" className="gap-xs">
					{filteredTeamList.map((teamMember) => (
						<TeamGridCard key={teamMember.email} {...teamMember} />
					))}
				</Flex>
			)}
			{teamMemberView === "list" && (
				<Flex wrap="wrap" className="gap-xs">
					{filteredTeamList.map((teamMember) => (
						<TeamListCard key={teamMember.email} {...teamMember} />
					))}
				</Flex>
			)}
			<ChangeTeamPermissionsModal />
		</Flex>
	);
};
