import { UserOutlined } from "@ant-design/icons";
import { useAction } from "@reatom/npm-react";
import { Avatar, Flex, Tag, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";

import {
	openChangeTeamPermissionsModalAction, setCurrentTeamMemberAction
} from "@/features/change-team-permissions-modal";
import { deleteTeamMemberAction } from "@/features/team-settings";
import { TEAM_MEMBER_MAP, TTeamMember } from "@/features/team-settings/lib";
import deleteIcon from "@/assets/shared/delete.svg";
import editIcon from "@/assets/shared/edit.svg";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import "./TeamListCard.scss";

export const TeamListCard: FC<TTeamMember> = ({ avatar, name, email, position, projectsCount, role, id }) => {
	const openModal = useAction(openChangeTeamPermissionsModalAction);
	const setCurrentTeamMember = useAction(setCurrentTeamMemberAction);
	const deleteTeamMember = useAction(deleteTeamMemberAction);

	const onEdit = () => {
		setCurrentTeamMember({
			role,
			position,
			email
		});
		openModal();
	};

	const onDelete = () => {
		deleteTeamMember({ email, teamId: id });
	};

	return (
		<Flex className="team-list-card full-width">
			<Flex align="center" justify="center" className="team-list-card__cell">
				<Avatar shape="square" size={50} icon={avatar ? <img src={avatar} alt={name} /> : <UserOutlined />} />
			</Flex>
			<Flex justify="center" vertical className="team-list-card__cell">
				<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>{name}</Typography.Text>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>{email}</Typography.Text>
			</Flex>
			<Flex className="team-list-card__cell" align="center">
				<span>
					<Tag className={cn("team-grid-card__tag", `team-grid-card__tag--${position}`)}>{TEAM_MEMBER_MAP[position].title}</Tag>
				</span>
			</Flex>
			<Flex className="team-list-card__cell" align="center">
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>{projectsCount} projects allowed</Typography.Text>
			</Flex>
			<Flex align="center" justify="flex-end" className="flex-1 gap-xs team-list-card__cell">
				<Flex onClick={onDelete} role="button" className="team-list-card__button pointer">
					<img src={deleteIcon} alt="delete" />
				</Flex>
				<Flex onClick={onEdit} role="button" className="team-list-card__button pointer">
					<img src={editIcon} alt="edit" />
				</Flex>
			</Flex>
		</Flex>
	);
};
