import { useAction, useAtom } from "@reatom/npm-react";
import { Button, ConfigProvider, Flex, Typography } from "antd";
import { FC, MutableRefObject, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { generateImageAtom, getStoryboardAction } from "@/entities/storyboard";
import { COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { TextLoader } from "@/shared/ui";
import { CollapsedScene } from "./CollapsedScene";

import emptyStoryboard from "@/assets/storyboard/empty-storyboard.svg";

import "./StoryboardView.scss";

type Props = {
	targetRef: MutableRefObject<any>;
}

export const StoryboardView: FC<Props> = ({ targetRef }) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [_generateImage, setGenerateImage] = useAtom(generateImageAtom);

	const getStoryboard = useAction(getStoryboardAction);
	const [storyboardList] = useAtom(getStoryboardAction.dataAtom);
	const [statuses] = useAtom(getStoryboardAction.statusesAtom);
	const onRedirect = () => {
		navigate(`/storyboard/${id}/script`);
	};

	useEffect(() => {
		if (id) {
			getStoryboard(id);
		}

		return () => {
			setGenerateImage({});
		};
	}, []);

	if (statuses.isPending) {
		return (
			<TextLoader />
		);
	}

	return (
		<Flex vertical className="storyboard-view full-width gap-xs">
			{!storyboardList.length && (
				<Flex vertical align="center" justify="center" className="gap-s full-height">
					<img className="storyboard-view__image" src={emptyStoryboard} alt="Empty Storyboard" />
					<Flex vertical className="text-center gap-xxs">
						<Typography.Text className={TEXT_CLASSNAMES.HeadlineH2}>You haven't created storyboard yet</Typography.Text>
						<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>Start creating script and storyboards</Typography.Text>
					</Flex>
					<Button type="primary" onClick={onRedirect}>
						<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Script</Typography.Text>
					</Button>
				</Flex>
			)}
			<Flex vertical className="gap-xs" ref={targetRef}>
				<ConfigProvider theme={{
					components: {
						Collapse: {
							headerBg: COLOR_NAMES.MetalGray50,
							contentBg: COLOR_NAMES.MetalGray50,
							contentPadding: "16px 24px",
							headerPadding: "16px 24px"
						}
					}
				}}>
					{storyboardList.map((scene) => (
						<CollapsedScene scene={scene} key={scene.id} />
					))}
				</ConfigProvider>
			</Flex>
		</Flex>
	);
};
